import useAsyncFetch from "./useAsyncFetch";

const gameName = ref("");
const tagIds = ref(new Set());
const isResponse = ref(false);

const [isExpanded, toggleExpand] = useToggle(false);

const getTagIds = () => {
	const selectedIds = Array.from(tagIds.value);
	const params = JSON.stringify(selectedIds);

	return params;
};

const useGameSearch = () => {
	const fetchOptions = () => ({
		query: {
			name: gameName.value.trim(),
			...(tagIds.value.size !== 0 && { tag_ids: getTagIds() })
		},
		onResponse: () => {
			isResponse.value = true;
		},
		onResponseError: () => {
			isResponse.value = true;
		}
	});

	const {
		data: dataGameSearch,
		pending: isLoading,
		execute
	} = useAsyncFetch({
		path: "/rest/search/",
		method: "get",
		options: {
			immediate: false
		},
		fetchOptions
	});

	const clearSearch = () => {
		gameName.value = "";
		tagIds.value.clear();
		isResponse.value = false;
		clearNuxtData("/rest/search/");
	};

	const handleClickExpand = (value: boolean) => {
		toggleExpand(value);

		if (!value) {
			clearSearch();
		}
	};

	const toggleTag = (id: number) => {
		if (tagIds.value.has(id) && isLoading.value) {
			return;
		}
		tagIds.value.has(id) ? tagIds.value.delete(id) : tagIds.value.add(id);

		if (tagIds.value.size === 0 && !gameName.value) {
			return clearSearch();
		}

		execute();
	};

	const handleSearch = useDebounceFn((value: string) => {
		if (!value && tagIds.value.size === 0) {
			return clearSearch();
		}

		execute();
	}, 500);

	return {
		dataGameSearch,
		gameName,
		tagIds,
		isLoading,
		isResponse,
		isExpanded,
		handleSearch,
		toggleTag,
		handleClickExpand,
		clearSearch
	};
};

export default useGameSearch;
